import { defineComponent, computed, ref, watch } from '@vue/composition-api';
import SpaceOption from '@/components/SpaceSelector/SpaceOption.vue';
import vClickOutside from 'v-click-outside';
export default defineComponent({
    components: {
        SpaceOption
    },
    directives: {
        clickOutside: vClickOutside.directive
    },
    props: {
        allVenueSpaces: {
            type: Array,
            default: () => []
        },
        preselected: {
            type: Boolean,
            default: false
        }
    },
    setup(props, context) {
        const { emit } = context;
        const open = ref(false);
        const selectedSpaceIds = ref([]);
        const spaces = computed(() => {
            return (props.allVenueSpaces?.map(s => ({
                name: s?.internal_name,
                id: s?.space_id
            })) || []);
        });
        const handleSpaceSelected = (isSelected, { id }) => {
            if (isSelected) {
                selectedSpaceIds.value.push(id);
            }
            else {
                const index = selectedSpaceIds.value.indexOf(id);
                if (index > -1) {
                    // only splice array when item is found
                    selectedSpaceIds.value.splice(index, 1); // 2nd parameter means remove one item only
                }
            }
            emit('input', selectedSpaceIds.value);
        };
        const handleSelectAll = (allSelected = true) => {
            if (allSelected) {
                selectedSpaceIds.value = props.allVenueSpaces?.map(s => s?.space_id);
            }
            else {
                selectedSpaceIds.value = [];
            }
            emit('input', selectedSpaceIds.value);
        };
        const triggerSpaceSelect = () => (open.value = !open.value);
        watch(() => props.allVenueSpaces, () => {
            if (props.allVenueSpaces?.length && props.preselected) {
                handleSelectAll(true);
            }
        }, { immediate: true });
        const selectedSpacesList = computed(() => spaces.value?.filter(s => selectedSpaceIds.value.includes(s.id)) || []);
        return {
            open,
            selectedSpaceIds,
            selectedSpacesList,
            spaces,
            handleSelectAll,
            handleSpaceSelected,
            triggerSpaceSelect
        };
    }
});
